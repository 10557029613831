import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { rem, getContrast, lighten } from 'polished';
import styled from 'styled-components';
import $ from 'jquery';

import { breakpoint } from '../../theme';

const Root = styled.div`
  width: 100%;

  .bv-lbl-title {
    font-size: ${rem(16)};

    ${breakpoint('mediumlarge')} {
      font-size: ${rem(18)};
    }
  }

  .bv-gui-select {
    /* margin: 0 !important; */
  }

  .bv-item {
    /* margin: 0 !important; */
    flex-shrink: 1;
    flex-grow: 1;

    &.bv-item-search {
      flex-shrink: 1;
      flex-grow: 0;
    }
  }

  &.bv-Standard1Column {
    label {
      &.bv-js-datepicker-input,
      &.bv-js-guestpicker-input {
        > span {
          line-height: ${rem(22)} !important;
          padding: ${rem(10)} ${rem(12)} !important;
          background: ${(props) =>
            props.theme.palette.colors.greyLight} !important;
          border: 0 !important;
          font-size: ${rem(18)} !important;
          font-family: ${(props) =>
            props.theme.typography.fonts.alt} !important;
          font-weight: ${(props) =>
            props.theme.typography.weights.alt.normal} !important;
        }
      }
    }

    a.bv-js-widget-button {
      line-height: ${rem(22)} !important;
      padding: ${rem(10)} ${rem(12)} !important;
      min-width: ${rem(100)};
      border: 0 !important;
      box-shadow: none !important;
      flex-shrink: 1;
      border-radius: ${(props) =>
        props.theme.components.button.radius} !important;
      font-family: ${(props) => props.theme.typography.fonts.button} !important;
      font-weight: ${(props) =>
        props.theme.typography.button.fontWeight} !important;
      font-size: ${rem(18)} !important;
      text-transform: ${(props) =>
        props.theme.typography.button.textTransform} !important;
      letter-spacing: ${(props) =>
        props.theme.typography.button.letterSpacing} !important;
      transition: ${(props) =>
        `all ${props.theme.global.transition.duration} ${props.theme.global.transition.timing}`} !important;

      color: ${(props) => {
        const contrastRatio = getContrast(
          props.theme.palette.colors.primary,
          '#fff',
        );
        return contrastRatio > 2.1 ? '#fff' : '#fff';
      }} !important;
      background: ${(props) => props.theme.palette.colors.primary} !important;

      &:hover {
        background: ${(props) =>
          lighten(
            props.theme.components.button.colorMultiplier,
            props.theme.palette.colors.primary,
          )} !important;
        color: ${(props) => {
          const contrastRatio = getContrast(
            props.theme.palette.colors.primary,
            '#fff',
          );
          return contrastRatio > 2.1 ? '#fff' : '#000';
        }} !important;
      }
    }
  }

  /* 
  &.bv-Standard1Column {
    .bv-widget {
      .bv-item {
        .bv-gui-btn,
        .bv-gui-select > span,
        input.bv-gui-text,
        label.bv-gui-text input,
        label.bv-gui-text > span,
        select {
          border-color: pink !important;
          height: 53px;
        }
      }
    }
  } */

  &.booking-widget-wide {
    .bv-lbl-title {
      display: none;
    }

    ${breakpoint('mediumlarge')} {
      .bv-item {
        padding-right: 12px;

        &:last-child {
          padding-right: 0;
        }
      }

      .bv-section-inner {
        display: flex;
        justify-content: stretch;
      }
    }
  }
`;

function BookingWidget({ variant }) {
  const [widgetHtml, setWidgetHtml] = useState(null);

  useEffect(() => {
    const baseUrl = 'https://online.bookvisit.com/v2/widget/getwidget';
    fetch(
      `${baseUrl}?channelId=7b262c95-0147-485a-956a-6dfddc118d08&culture=sv-SE&containerId=widgetContainer&layout=Standard1Column&openOption=iframe&displayPromoCode=false&displayCorpCode=false&displayIATACode=false`,
    )
      .then((response) => response.json()) // parse JSON from request
      .then((resultData) => {
        setWidgetHtml(resultData.Widget);
      });
  }, []);

  useEffect(() => {
    // TODO: On a re-render this seem to be initiated several times which makes the "Search" button to open several tabs and also add several rooms when clicking "Add room"
    $('#widgetContainer').html(widgetHtml);
  }, [widgetHtml]);

  const classnames = ['booking-widget'];

  if (variant === 'wide') {
    classnames.push('booking-widget-wide');
  }

  return (
    <>
      <Helmet>
        <script
          src="https://online.bookvisit.com/Content/flexwidget/FlexWidgetFullScripts.min.js"
          defer
        />
      </Helmet>

      <Root
        id="widgetContainer"
        className={classnames.join(' ')}
        // dangerouslySetInnerHTML={{ __html: widgetHtml }} // eslint-disable-line react/no-danger>
      />
    </>
  );
}

BookingWidget.propTypes = {
  variant: PropTypes.oneOf(['default', 'wide']),
};

BookingWidget.defaultProps = {
  variant: 'default',
};

export default BookingWidget;

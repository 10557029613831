import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { useTranslation } from 'react-i18next';
import { getSrc } from 'gatsby-plugin-image';

import useLangRedirect from '../hooks/useLangRedirect';

import SEO from '../components/SEO';
import Layout from '../components/Layout';
import { GridContainer } from '../components/Grid';
import Row from '../components/Row';
import Col from '../components/Col';
import Hero from '../components/Hero';
import BookingWidget from '../components/BookingWidget';
import ImageBoxItem from '../components/ImageBoxItem';
import LogoTag from '../components/LogoTag';
import SliceZone from '../components/SliceZone';

import { SectionTitle } from '../utils/GlobalFormatting';
import { breakpoint } from '../theme';
import { SiteInformation, PageContent, PackageItems } from '../utils/fragments';

import fallbackVideo from '../images/hero.mp4';

const Section = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: ${rem(350)};
  padding-top: ${rem(60)};
  padding-bottom: ${rem(60)};
  margin: ${rem(0)} auto 0;

  ${breakpoint('mediumlarge')} {
    min-height: ${rem(450)};
  }

  ${breakpoint('large')} {
    min-height: ${rem(550)};
  }
`;

const BookingSection = styled(Row)`
  /* width: 100%; */
  max-width: ${(props) => props.theme.grid.maxWidth};
  margin: ${rem(0)} auto 0;
`;

const BookingRow = styled.div`
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.palette.colors.primary};
  padding-top: ${rem(30)};
  padding-bottom: ${rem(30)};
  align-items: center;

  ${breakpoint('mediumlarge')} {
    display: flex;
  }

  h3 {
    margin: 0 0 ${rem(24)} 0;
    white-space: nowrap;

    ${breakpoint('mediumlarge')} {
      margin: 0 ${rem(30)} 0 0;
      flex-basis: 28%;
    }
  }
`;

const IntroCol = styled(Col)`
  width: 100%;
  margin-top: ${rem(25)};

  ${breakpoint('large')} {
    margin-top: ${rem(18)};
  }

  ${({ count }) => {
    switch (count) {
      case 1:
      default:
        return css`
          width: 100%;
        `;
      case 2:
        return css`
          ${breakpoint('large')} {
            width: 50%;
          }
        `;
      case 3:
        return css`
          ${breakpoint('mediumlarge')} {
            width: 33.333%;
          }

          ${breakpoint('large')} {
            width: 33.333%;
          }
        `;
      case 4:
        return css`
          ${breakpoint('mediumlarge')} {
            width: 50%;
          }

          ${breakpoint('large')} {
            width: 25%;
          }
        `;
    }
  }}
`;

function Home({ data }) {
  const { page, testimonials } = data;
  const { t } = useTranslation();

  useLangRedirect(page);

  if (!page) {
    return null;
  }

  const {
    featuredImage,
    // elementorDataRendered,
    // elementorEditMode,
    lang,
    seo,
    sections: pageSections,
    frontPageDetails: { blurbs, introTitle, introContent },
    pageOptions: { heroSize },
    hero,
  } = page;

  // const isCustomLayout = elementorEditMode === 'builder';
  const featuredMedia = featuredImage?.node;
  const itemImageSrc = getSrc(featuredMedia?.localFile);
  const backgroundVideo = hero?.backgroundvideo?.localFile?.url;
  const videoPoster = hero?.backgroundvideoposter;

  let frontPageSections = [...pageSections.sections];

  const insert = (arr, index, newItem) => [
    // part of the array before the specified index
    ...arr.slice(0, index),
    // inserted item
    newItem,
    // part of the array after the specified index
    ...arr.slice(index),
  ];

  if (testimonials.edges.length > 0) {
    const testimonialSection = {
      type: 'Testimonials',
      items: testimonials,
      title: t('testimonialSectionTitle'),
    };

    frontPageSections = insert(frontPageSections, 6, testimonialSection);
  }

  return (
    <Layout headerProps={{ toggleLogoOnStuck: heroSize !== 'default' }}>
      <SEO
        title={seo.title}
        description={seo.metaDesc || seo.opengraphDescription}
        image={itemImageSrc}
        page={page}
        lang={lang}
      />

      <Hero
        heading={page.title}
        image={videoPoster?.localFile || featuredMedia?.localFile}
        pageTitle
        // actions="Actions"
        tint
        big
        logo
        videoProps={{ src: backgroundVideo || fallbackVideo }}
        animated
      />

      <BookingSection>
        <BookingRow>
          <h3>{t('bookTitle')}</h3>
          <BookingWidget variant="wide" />
        </BookingRow>
      </BookingSection>

      {/* TODO: Output specific front page blocks by iteration count like widgets on Siljan News */}

      <Section>
        <GridContainer>
          <GridContainer
            narrow
            style={{ textAlign: 'center', fontSize: rem(20) }}
          >
            <Row>
              <Col>
                <SectionTitle big>{introTitle}</SectionTitle>
                <div
                  dangerouslySetInnerHTML={{ __html: introContent }} // eslint-disable-line react/no-danger
                />
                <LogoTag />
              </Col>
            </Row>
          </GridContainer>
          <Row>
            {blurbs?.map((blurb, index) => {
              return (
                <IntroCol
                  key={`blurb-${index}`} // eslint-disable-line react/no-array-index-key
                  count={blurbs.length}
                  data-sal="slide-up"
                  data-sal-duration="450" // changes duration of the animation (from 200 to 2000 ms)
                  data-sal-delay="25" // adds delay to the animation (from 5 to 1000 ms)
                  data-sal-easing="easeOutExpo" // sets easing for the animation (see easings.net for reference)
                >
                  <ImageBoxItem
                    image={blurb.image?.localFile}
                    title={blurb.content.title}
                    // text={blurb.content.text}
                    subText={blurb.content.subText}
                    link={blurb.button.link}
                  />
                </IntroCol>
              );
            })}
          </Row>
        </GridContainer>
      </Section>

      {/* isCustomLayout ? (
        <div
          dangerouslySetInnerHTML={{ __html: elementorDataRendered }} // eslint-disable-line react/no-danger
        />
      ) : (
        <>
          {page.content && (
            <Container>
              <Row>
                <Col>
                  <PostContent
                    dangerouslySetInnerHTML={{ __html: page.content }} // eslint-disable-line react/no-danger
                  />
                </Col>
              </Row>
            </Container>
          )}
        </>
          ) */}

      <SliceZone slices={frontPageSections} />
    </Layout>
  );
}

Home.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

Home.fragments = [PageContent, SiteInformation, PackageItems];

export const query = graphql`
  query ($id: String!, $lang: String!) {
    page: wpPage(id: { eq: $id }) {
      elementorDataRendered
      elementorEditMode
      frontPageDetails {
        blurbs {
          ... on WpPage_Frontpagedetails_Blurbs_BlurbDefault {
            __typename
            button {
              link
              label: text
            }
            content {
              title
              subText
            }
            image {
              id
              altText
              caption
              localFile {
                id
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    width: 800
                    height: 560
                    quality: 75
                    transformOptions: { cropFocus: ENTROPY }
                    layout: CONSTRAINED
                  )
                }
              }
            }
          }
        }
        introTitle
        introContent
      }
      ...PageContent
    }
    site {
      ...SiteInformation
    }
    testimonials: allWpTestimonial(filter: { lang: { eq: $lang } }, limit: 12) {
      edges {
        node {
          id
          content
          title
          lang
        }
      }
    }
  }
`;

export default Home;
